import { arrayOf, object, shape } from 'prop-types'
import { graphql } from 'gatsby';
import React from 'react'

import { sortProductsByDate } from '../utils/functions'
import About from '../components/About'
import config from '../../config/website'
import Header from '../components/Header'
import ProductList from '../components/ProductList'

const IndexPage = ({data: {allSanityProduct}}) => {
  const nodes = allSanityProduct.edges.map(p => p.node)
  const products = sortProductsByDate(nodes, 'DESC')

  return (
    <>
      <Header siteTitle={config.siteTitle} />
      <div>
        <ProductList products={products} />
        <About />
      </div>
    </>
  )
}

IndexPage.propTypes = {
  data: shape({
    allSanityProduct: shape({
      edges: arrayOf(object)
    }).isRequired,
  }).isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allSanityProduct(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          creator
          created
          price
          available
          cover {
            asset {
              url
            }
          }
          images {
            asset {
              url
            }
          }
          body {
            fr {
              children {
                text
              }
            }
          }
        }
      }
    }
  }
`;