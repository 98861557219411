import moment from 'moment'

export const timestampify = string => moment(string).format('x')

export const sortProductsByDate = (products, order) => {
  const sorted = products.sort(
    (p1, p2) => timestampify(p1.created) - timestampify(p2.created)
  )

  return order === 'DESC' ? sorted.reverse() : sorted
}
