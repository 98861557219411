import { bool, number, object, shape, string } from 'prop-types'
import { Link } from 'gatsby'
import React from 'react'

import BuyButton from './BuyButton'
import config from '../../config/website'

const Product = ({data: product}) => {
  const {
    id,
    cover,
    name,
    slug: {current: slug},
    price,
    available,
  } = product
  const path = `/product/${slug}`
  
  return (
    <article key={id} className='fl w-100 w-50-m w-25-ns pv2 ph3 pa2-ns'>
      <Link to={path} 
        className="db hide-child no-underline cover bg-center aspect-ratio aspect-ratio--1x1" 
        style={{backgroundImage: `url(${cover.asset.url})`}}
      >
        <div className="bg-vvood-50 child flex items-baseline content-center justify-center absolute absolute--fill">
          <div className="tc self-center">
            <div className="dib f3 b white">
              {name}
              {config.shopEnabled && 
                (!available && <div className="f7 ttu tracked normal o-60">Vendu</div>) &&
                <BuyButton id={id} name={name} price={price} hidden />
              }
            </div>
          </div>
        </div>
      </Link>
    </article>
  )
}

Product.propTypes = {
  data: shape({
    product: shape({
      id: string.isRequired,
      cover: object.isRequired,
      name: string.isRequired,
      price: number,
      available: bool,
    })
  })
}

export default Product