import React from 'react'

const About = () => (
  <article className="cf pv3 ph3 ph1-ns mw9 center">
    <header className="fl w-100 pa2-m pa3-l mt5 mb1 mb2-l">
      <h2 className="lh-title f2 tc fw3 tracked mt0">Merci le bois !</h2>
    </header>
    <section className="fl w-100">
      <div className="fl w-100 w-50-l">
        <div className="fl w-100 w-50-m w-50-l pa2-m pa3-l">
          <p className="f6 lh-copy measure">
            {/* Pariatur irure est ad mollit quis do laborum nostrud velit dolore
            eiusmod minim tempor. Commodo eu irure pariatur voluptate excepteur
            Lorem laborum aute non. Sit cillum culpa elit ex deserunt qui. Et enim
            irure anim in incididunt id labore mollit deserunt anim ad laborum
            deserunt pariatur. Ut nisi irure deserunt magna deserunt qui in
            consequat. Occaecat tempor enim aliqua eu. Ea excepteur consequat nisi
            fugiat. */}
          </p>
        </div>
        <div className="fl w-100 w-50-m w-50-l pa2-m pa3-l">
          <p className="f6 lh-copy measure tc">
            Valmon aka •V
          </p>
        </div>
      </div>
      <div className="fl w-100 w-50-l">
        <div className="fl w-100 w-50-m w-50-l pa2-m pa3-l">
          <p className="f6 lh-copy measure tc">
            Vincent aka V•
          </p>
        </div>
        <div className="fl w-100 w-50-m w-50-l pa2-m pa3-l">
          <p className="f6 lh-copy measure">
            {/* Id irure eiusmod elit sit minim aliquip cillum aute. Esse dolore
            nostrud aliqua enim ullamco duis aliquip do est nulla labore officia.
            Culpa Lorem tempor adipisicing duis laboris deserunt amet. Ex minim eu
            nisi cillum deserunt. Esse occaecat ullamco irure magna nisi nostrud
            officia velit in labore irure excepteur culpa. Elit elit laboris
            laboris ipsum veniam eiusmod occaecat nostrud sint. Veniam excepteur
            ut culpa quis do non ullamco consequat Lorem consequat esse. */}
          </p>
        </div>
      </div>
    </section>
  </article>
)

export default About
