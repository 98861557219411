import {arrayOf, shape, string} from 'prop-types'
import React from 'react'

import Product from './Product';

const ProductList = ({products}) => (
  <section className="cf w-100 pa2-ns">
    {products && 
      products.map(product => 
        <Product key={product.id} data={product} />
      )
    }
  </section>
)

ProductList.propTypes = {
  products: arrayOf(shape({
    id: string.isRequired
  }))
}

export default ProductList

