import { bool, string } from 'prop-types'
import React from 'react'

const BuyButton = ({ id, name, price, url, text, hidden }) => 
  <button
    className={`snipcart-add-item f6 link dim br1 ba ph3 pv2 ${hidden ? 'dn' : 'dib'} bg-vvood white`}
    data-item-id={id}
    data-item-name={name}
    data-item-price={Number.parseFloat(price).toFixed(2)}
    data-item-url={url || `/product/${id}`}>
    {text || 'Acheter'}
  </button>

BuyButton.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  price: string.isRequired,
  url: string,
  text: string,
  hidden: bool,
}

export default BuyButton